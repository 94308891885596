import dashboard from './dashboard';
import managements from './managment';
import hrms from './hrms';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, managements, hrms]
};

export default menuItems;
